/* =========== Fonts =========== */
@import url("https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Poppins:wght@300;400;500;600&display=swap");
/* =========== colors =========== */
/* =========== font sizes =========== */
/* =========== common css ===========*/
body {
  margin: 0;
  padding: 0;
  
  font-family: "Poppins", sans-serif;
  font-size: 0.85rem;
  color: #1F323E;
  overflow-x: hidden;
}

h1 {
  color: #EFF4F5;
  font-size: 1.45rem;
  padding: 0;
  margin: 0;
  font-family: "Bebas Neue", sans-serif;
  font-weight: 500;
  letter-spacing: 0.7px;
}

h2 {
  font-size: 1rem;
  color: #1F323E;
  padding: 0;
  margin: 0;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  letter-spacing: 0.3px;
}

h4 {
  color: #EFF4F5;
  font-size: 1rem;
  padding: 0em 0em;
  margin: 0;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
}

p {
  font-size: 0.85rem;
  margin: 0px 0px 10px;
  padding: 0;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-style: normal;
  line-height: 24px;
  color: #1F323E;
}

.border-bottom {
  border-bottom: 1px solid #ddd;
}

.text-align-right {
  text-align: right;
}

.container {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100vh;
  float: left;
}

/* ==========
   login screen css 
   ========== */
/* top status bar */
.Status_bar {
  width: calc(100% - 30px);
  float: left;
  margin: 0;
  padding: 10px 15px;
  font-family: "Poppins", sans-serif;
}

.Status_bar ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.Status_bar li {
  display: inline-block;
  color: #EFF4F5;
  font-weight: 500;
  margin: 0px 5px 0px 0px;
}

.time, .network {
  width: 50%;
  float: left;
}

/* company logo */
.company_logo {
  width: 100%;
  float: left;
  margin: 0px;
  padding: 25% 0 15%;
  text-align: center;
}

.company_logo img {
  width: 225px;
}

/* login form */
.login_form {
  width: calc(100% - 50px);
  float: left;
  margin: 0px;
  padding: 10% 25px;
  text-align: center;
}

.login_form .form_mb {
  margin: 0px 0px 20px;
  display: flex;
}

.login_form label {
  width: 100%;
  float: left;
  display: flex;
  position: relative;
}

.login_form input {
  width: 100%;
  outline: none;
  display: block;
  padding: 1rem;
  font-weight: 400;
  color: #1F323E;
  background-color: #EFF4F5;
  border: 1px solid #134C86;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  border-radius: 0.45rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  font-family: "Poppins", sans-serif;
}

.login_form input::-moz-placeholder {
  opacity: 0;
}

.login_form input::placeholder {
  opacity: 0;
}

.login_form span {
  position: absolute;
  top: 0;
  left: 0;
  transform: translateY(15px);
  transition-duration: 300ms;
  color: rgba(31, 50, 62, 0.8);
  font-family: "Poppins", sans-serif;
  padding: 0 1.25rem;
}

.login_form button {
  width: 100%;
  padding: 1rem;
  background: #398CD5;
  color: #EFF4F5;
  border: 1px solid #134C86;
  cursor: pointer;
  border-radius: 0.45rem;
  font-family: "Poppins", sans-serif;
  font-size: 1rem;
  font-weight: 500;
  letter-spacing: 0.5px;
}

.login_form input:not(:-moz-placeholder-shown) + span {
  color: #4b4b4b;
  transform: translateY(-5px);
  padding: 2px 7px;
  background: #fff;
  border-radius: 3px;
  margin: 0px 0px 0px 15px;
  font-size: 10px;
  letter-spacing: 0.5px;
}

.login_form label:focus-within > span,
.login_form input:not(:placeholder-shown) + span {
  color: #4b4b4b;
  transform: translateY(-5px);
  padding: 2px 7px;
  background: #fff;
  border-radius: 3px;
  margin: 0px 0px 0px 15px;
  font-size: 10px;
  letter-spacing: 0.5px;
}

.login_form .int-icon {
  position: absolute;
  top: 16px;
  right: 20px;
  width: 24px;
}

.login_form .form_sign_up {
  width: 100%;
  margin: 0px;
  text-align: center;
}

.login_form .form_sign_up p {
  color: #EFF4F5;
}

.login_form .form_sign_up p a {
  color: #EFF4F5;
}

/* ==========
   my profile screen css 
   ========== */
/* top Trailing icons */
.trailing_icons {
  width: calc(100% - 30px);
  float: left;
  margin: 0px;
  padding: 10px 15px;
  text-align: center;
}

.trailing_icons ul {
  width: 100%;
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.trailing_icons li {
  display: flex;
  color: #EFF4F5;
  font-weight: 500;
  margin: 0px;
}

/* user profile */
.user_profile {
  width: calc(100% - 30px);
  float: left;
  margin: 0px;
  padding: 30px 15px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
}

.user_pic {
  width: 70px;
  float: left;
  margin: 0px 15px 0px 0px;
  padding: 0%;
}

.user_pic img {
  width: 70px;
}

.user_details {
  float: left;
  margin: 0px;
  padding: 0%;
}

.user_details ul {
  width: 100%;
  margin: 0;
  padding: 0;
  list-style: none;
}

.user_details li {
  margin: 0px;
  padding: 0;
}

.user_details li h1 {
  margin: 0px 0px 2px;
  padding: 0;
}

.user_mail {
  color: #93C4EF;
  display: flex;
  align-items: center;
}

.user_mail span {
  margin: 0px 5px 2px 0px;
  display: flex;
}

/* Policy cards */
.content_box {
  width: calc(100% - 40px);
  float: left;
  margin: 0 0 5% 0;
  padding: 20px;
  border-radius: 1rem 1rem 0rem 0rem;
  background: #fff;
}

.policy_head {
  width: 100%;
  float: left;
  text-align: center;
  margin: 0px 0px 2% 0px;
  padding: 0;
}

.policy_cards {
  width: 100%;
  float: left;
  margin: 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.item_card {
  width: calc(33% - 18px);
  float: left;
  margin: 7px;
  padding: 0px;
  border: 1px solid #E6E6E6;
  border-radius: 0.35rem;
  text-align: center;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  cursor: pointer;
}

.item_card .icon_box {
  width: calc(100% - 10px);
  float: left;
  margin: 0;
  padding: 12px 5px 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}

.item_card .text_box {
  width: calc(100% - 10px);
  float: left;
  margin: 0;
  padding: 7px 5px;
  font-size: 0.75rem;
  background: #EFF4F5;
  border-radius: 0px 0px 4px 4px;
  font-weight: 500;
}

.item_card:hover {
  border: 1px solid #398cd5;
  box-shadow: 0px 2px 25px 0px rgba(19, 76, 134, 0.28);
}

/* claim track */
.claim_track {
  width: calc(100% - 20px);
  float: left;
  margin: 0px;
  padding: 5% 10px;
  display: flex;
  justify-content: space-between;
}

.btn_trackclaim {
  cursor: pointer;
  width: 48%;
  margin: 0;
  padding: 1rem;
  color: #1F323E;
  background: #fff;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
  font-size: 0.75rem;
  border: 1px solid #E6E6E6;
  border-radius: 0.35rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.btn_trackclaim span {
  margin: 0px 10px 0px 0px;
  display: flex;
}

/* news cards */
.news_update {
  width: 100%;
  float: left;
  margin: 10% 0;
  padding: 0;
  border: aqua;
}

.news_row {
  width: 100%;
  height: auto;
  float: left;
  padding: 0px 0px 5% 0px;
  margin: 0px 0px 5% 0px;
  display: flex;
  flex-direction: row;
}

.news_img {
  width: 33%;
  float: left;
  margin: 0;
  padding: 0;
}

.news_img img {
  width: 100%;
  margin: 0;
  padding: 0;
}

.news_content {
  width: 60%;
  float: left;
  margin: 0;
  padding: 0px 0px 0px 15px;
}

.news_content p {
  font-size: 0.85em;
  margin: 0px 0px 5px;
  padding: 0;
  line-height: 22px;
}

.news_content p a {
  color: #398CD5;
  text-decoration: none;
}

.news_content h2 {
  margin: 0px 0px 5px;
}

/* ==========
   File a claim screen css 
   ========== */
/* claim detail cards */
.claim_row {
  width: calc(100% - 50px);
  height: auto;
  float: left;
  padding: 5% 25px;
  margin: 0px 0px 5%;
  background-color: #F7F7F7;
  border-radius: 10px;
}

.claim_head {
  width: 100%;
  height: auto;
  float: left;
  padding: 0px;
  margin: 0px 0px 4%;
}

.claim_head h4 {
  color: #1F323E;
  font-weight: 500;
}

.dflex_claim {
  display: flex;
  width: 100%;
  float: left;
  justify-content: space-between;
}

.claim_content {
  width: 100%;
  float: left;
  margin: 0;
  padding: 0px;
}

.loop_claim {
  width: 100%;
  float: left;
  margin: 0px 0px 2%;
}

.claim_content ul {
  width: 100%;
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  justify-content: space-between;
}

.claim_content li {
  font-size: 14px;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  color: #1F323E;
}

.claim_content p {
  font-size: 0.85em;
  margin: 0px 0px 5px;
  padding: 0;
  line-height: 22px;
}

/* file a claim btns */
.file_claim_btngrp {
  width: calc(100% - 36px);
  height: auto;
  float: left;
  padding: 5%;
  margin: 0px 0px 5%;
  border-radius: 10px;
}

.btn_primary {
  cursor: pointer;
  width: 100%;
  height: auto;
  margin: 0px 0px 5%;
  padding: 1rem;
  color: #EFF4F5;
  background: #398CD5;
  border: none;
  font-weight: 500;
  border-radius: 8px;
  font-size: 1rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.btn_primary span {
  margin: 0 10px;
  display: flex;
}

.btn_secondary {
  cursor: pointer;
  width: 100%;
  height: auto;
  margin: 0px 0px 5%;
  padding: 1rem;
  color: #1F323E;
  background: #fff;
  border: 1px solid #E6E6E6;
  font-weight: 500;
  border-radius: 8px;
  font-size: 1rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.btn_secondary span {
  margin: 0 10px;
  display: flex;
}

/* footer login screen */
.footer_light {
  width: 100%;
  float: left;
  margin: 0px;
  padding: 3% 0;
  text-align: center;
}

.footer_light p {
  color: #EFF4F5;
  font-size: 0.75rem;
}

.footer_light p a {
  color: #EFF4F5;
  text-decoration: none;
}

/* footer my profile screen */
.footer_dark {
  width: 100%;
  float: left;
  margin: 0px;
  padding: 3% 0;
  text-align: center;
}

.footer_dark p {
  color: #1F323E;
  font-size: 0.75rem;
}

.footer_dark p a {
  color: #1F323E;
  text-decoration: none;
}

.toggle-el {
  transition: all 0.2s ease;
  opacity: 1;
}

input[type=checkbox].hide-input:checked + .toggle-el {
  height: 0;
  opacity: 0;
  padding-top: 0;
  padding-bottom: 0;
}

input.hide-input {
  position: absolute;
  left: -999em;
}

label.toggle {
  cursor: pointer;
}/*# sourceMappingURL=default.css.map */